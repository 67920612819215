import { Alert, Button, Card, Col, Collapse, Form, Icon, Input, List, Modal, Row, Select, Spin, Table } from "antd";
import _ from "lodash";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as apiUtils from "../../../common/utils/apiGateway";
import { Constants, FlowTypes, UPLOAD_BUTTON_TYPE } from "../../../common/utils/constants";
import { removeDuplicateLocations, trimByConfig } from "../../../common/utils/utils";
import { canAllow, notifyApiError, notifyApiSuccess } from "../../../common/utils/utils.js";
import { permissionTags } from "../../../configs/permissionTags.js";
import { SoundNotificationService } from "../../../services/sound.service";
import PrintButton from "../../shared-components/common-label-sticker/print-component";
import ScanActionRequiredPopupModal from "../../shared-components/scan-action-required-modal";
import WaybillLink from "../../shared-components/waybill-link/index";
import FileUpload from "../../ui/file-upload/file.upload";
import {
    createManifests, deLinkWaybill, linkWaybill, fetchDestinationLoc,
    fetchLinkedWaybill, fetchPendingWaybillCount, fetchSingleManifest,
    getLinkedWaybill, createRtoManifest, fetchRtoSingleManifest, linkRTOWaybill, deLinkRTOWaybill,
    generateManifestCode, lockUnlockManifest, fetchRtoPendingWaybillCount,
    fetchRtoDestinationLoc
} from "./actions.js";
import ManifestMwebScan from "./manifest.scan";
import "./manifest.scss";
;

const soundNotificationService = new SoundNotificationService;

class ManifestForm extends Component {
    constructor(props) {
        super(props);
        let editManifestID = undefined;
        this.isMobile = (window.outerWidth <= 600 && window.location.pathname?.indexOf("mweb") > -1);

        let flowType = "FORWARD";
        if (props?.location?.pathname.includes("inventory")) {
            flowType = "FORWARD";
        } else if (props?.location?.pathname.includes("rto")) {
            flowType = "RTO";
        };

        this.selectedDestinationName = undefined;

        this.state = {
            partners: [],
            acceptTypes: [".xls", ".xlx", ".xlsx"],
            uploadedFile: "",
            clearManifestFile: false,
            destinations: [],
            pendingAwb: [],
            pendingAwbCount: 0,
            pendingAwbCountLoading: false,
            selectedAwb: [],
            disableButton: true,
            showWaybillScan: false,
            manifest: "",
            deepLinkUrl: undefined,
            waybill: undefined,
            manifestId: "",
            generatedManifest: false,
            isEdit: false,
            isLocked: false,
            editManifestID,
            pageNo: 1,
            pageSize: 10,
            selectedConsignmentBO: {},
            selectedAwbCurrentPageNo: 1,
            IsPartnerLocationIdEqualsManifestLocationId: false,
            loaders: {
                consignmentLoading: false,
                createLoading: false,
                selectedLoading: false,
                destinationDisabled: false,
                manifestLockLoading: false,
            },
            searchText: "",
            searchedColumn: "",
            selectedColumns: [
                {
                    title: "AWB No.",
                    dataIndex: "waybillNo",
                    key: "waybillNo",
                    ...this.getColumnSearchProps("waybillNo"),
                    render: (data) => <WaybillLink>{data}</WaybillLink>,

                },
                ...(!(window.outerWidth <= 600 && window.location.pathname?.indexOf("mweb") > -1) ? [{
                    title: "Customer",
                    dataIndex: "customer",
                    key: "customer",
                    render: data => data?.name
                },
                {
                    title: "Destination",
                    dataIndex: "pincode",
                    key: "pincode",
                    render: data => <>{data?.city?.cityCode} - {data?.zipcode}</>
                }] : []),
                {
                    title: "Action",
                    dataIndex: "action",
                    render: (data, row) => <Button onClick={() => this.removeWaybill(row)}><Icon type="minus" /></Button>
                }

            ],
            rearchSelectedColumns: [
                {
                    title: "AWB No.",
                    dataIndex: "waybillNo",
                    key: "waybillNo",
                    ...this.getColumnSearchProps("waybillNo"),
                    render: (data) => <WaybillLink>{data}</WaybillLink>,

                },
                ...(!(window.outerWidth <= 600 && window.location.pathname?.indexOf("mweb") > -1) ? [{
                    title: "Customer",
                    dataIndex: "customerName",
                    key: "customerName"
                },
                {
                    title: "Destination",
                    dataIndex: "destinationLocationName",
                    key: "destinationLocationName"
                }] : []),
                {
                    title: "Action",
                    dataIndex: "action",
                    render: (data, row) => <Button onClick={() => this.removeWaybill(row)}><Icon type="minus" /></Button>
                }

            ],
            visibleBulk: false,
            bulkSuccess: [],
            bulkFail: [],
            bulkData: undefined,
            bookingSuccessMsg: "Operation Success",
            bookingFailMsg: "Operation Fail",
            errorResponseMsg: "Show / Hide Error Response",
            totalBookingText: "Total Counts: ",
            successfulBookingText: "Successful Counts: ",
            unsuccessfulBookingText: "Unsuccessful Counts: ",
            unsuccessfulAsyncText: "Unsucessful Asset Assign: ",
            bulkLoading: false,
            manifestPermission: {
                bulk: canAllow(permissionTags.inventory.manifest_partner.bulkCreate),
            },
            isManifestLockButtonEnabled: false,
            showConfirmLockModal: false,
            flowType: flowType,
            scanResult: {},
            pageDataLoading: false,
            selectedAWBModal: false,
            manifestCodeFilled: false,
            linkedWaybillPaginatedRes: {
                totalCount: 0,
                maxPage: 1
            },
            reloadCountLoader: false
        }
        this.fetchSourceLocation = debounce(this.fetchSourceLocation, 800);
    }

    componentDidMount() {

        const queryParams = new URLSearchParams(this.props.location.search);
        const isLocked = queryParams.get("isLocked") ? (queryParams.get("isLocked") === "true") : false;
        let isEdit = this.props?.match?.params?.id ? true && (!isLocked) : false;
        let stateData = {
            editManifestID: this.props?.match?.params?.id,
            isLocked: isLocked,
            isManifestLockButtonEnabled: isLocked,
            isEdit: isEdit
        }

        if (this.props.appConfig && this.props.appConfig.manifest_lock_and_print_enabled_locations &&
            this.props.appConfig.manifest_lock_and_print_enabled_locations.indexOf(Constants.LOGGED_PARTNER_LOCATION_ID + "") > -1) {

            stateData = {
                ...stateData,
                ...this.state,
                IsPartnerLocationIdEqualsManifestLocationId: true,
                pageDataLoading: this.state.isEdit || this.state.isLocked,
            }

        }

        this.setState(stateData);

    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (prevState.isEdit != this.state.isEdit && this.state.isEdit) ||
            (prevState.isLocked != this.state.isLocked && this.state.isLocked)
        ) {

            const editManifestID = this.props?.match?.params?.id;

            //TODO: Remove validation using regex pattern once rearch flow is stable.
            const regexPattern = /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/;

            if (editManifestID &&
                ((this.state.flowType === FlowTypes.FORWARD
                    && this.props.appConfig?.enableRearch?.enableManifestReadOps === 'true') ||
                    (this.state.flowType === FlowTypes.RTO &&
                        this.props.appConfig?.enableRearch?.enableRtoManifestReadOps === "true")
                ) &&
                !regexPattern.test(editManifestID)) {
                const destModule = (this.state.flowType === FlowTypes.FORWARD ? "inventory" : "rto")

                if (window.location.pathname?.indexOf("mweb") > -1) {
                    this.props.history.push('/appv2/mweb/home')
                } else {
                    this.props.history.push(`/appv2/${destModule}/dashboard/manifest-partner`)
                }

            } else {
                this.loadManifestData();
            }
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input ref={node => {
                    this.searchInput = node;
                }} placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]} onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }} />
                <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search" size="small" style={{ width: 90, marginRight: 8 }} >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                text
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    loadManifestData = () => {

        this.setState({ reloadCountLoader: true })

        let manifestId = this.state.editManifestID || this.state.manifestId;

        if (
            this.state.flowType === FlowTypes.FORWARD &&
            this.props.appConfig?.enableRearch?.enableManifestReadOps === 'true'
        ) {
            // let manifestCode = drsID
            // console.log('manifestCode', manifestCode)
            // if (window.location.pathname?.indexOf("mweb") > -1) {
            //     let lastIndex = this.props?.location?.pathname.lastIndexOf("/");
            //     manifestCode = this.props?.location?.pathname.slice(lastIndex + 1)
            // }

            manifestId = this.state.manifestId

            fetchSingleManifest(manifestId)
                .then(response => {
                    this.handleFetchManifestSuccessRes(response)
                    this.setState({ reloadCountLoader: false })
                })
                .catch(err => {
                    console.error("fetchSingleManifest failed with following error", err)
                    this.setState({ reloadCountLoader: false })
                })

            const paginationReq = {
                pageNo: 1
            }

            this.fetchPaginationLinkedWaybill(paginationReq)

        } else if (this.state.flowType === FlowTypes.RTO &&
            this.props.appConfig?.enableRearch?.enableRtoManifestReadOps === "true") {

            manifestId = this.state.manifestId

            fetchRtoSingleManifest(manifestId)
                .then(response => {
                    this.handleFetchManifestSuccessRes(response)
                    this.setState({ reloadCountLoader: false })
                })
                .catch(err => {
                    console.error("fetchSingleManifest failed with following error", err)
                    this.setState({ reloadCountLoader: false })
                })

            const paginationReq = {
                pageNo: 1
            }

            this.fetchPaginationLinkedWaybill(paginationReq)

        } else {
            apiUtils.apiRequest({
                url: `/b2b/v1/manifest/${manifestId}`,
                method: "GET"
            }).then(response => {
                let { selectedAwb, loaders } = this.state;
                const { form } = this.props;
                const manifestData = response?.data?.response?.manifests[0];
                const destinations = [];
                destinations.push(manifestData.destinationLoc)
                selectedAwb = manifestData.consignments == null ? [] : manifestData.consignments;
                loaders = {
                    ...this.state.loaders,
                    destinationDisabled: true,
                }


                this.setState({ manifestData: manifestData, destinations, selectedAwb, generatedManifest: true, showWaybillScan: true, disableButton: true, manifest: manifestData.manifestCode, manifestId: manifestData?.id, loaders }, () => {
                    form.setFieldsValue({
                        manifestID: manifestData.manifestCode,
                        destination: manifestData.destinationLoc.id,
                        destinationType: manifestData.destinationLoc.entityType,
                        partner: manifestData.destinationLoc.entityType === "CUSTOMER" ? manifestData.destinationLoc.entity?.custObject?.partnerId : manifestData.destinationLoc.entityId
                    }, () => {
                        this.setState({ pageDataLoading: false });
                        this.getConsignments(manifestData.destinationLoc.id);
                    })
                })

                var module = "inventory";
                if (this.state.flowType !== FlowTypes.FORWARD) {
                    module = 'rto';
                }

                this.setState({ reloadCountLoader: false })

                this.props.history.replace(`/appv2${this.isMobile ? '/mweb' : ''}/${module}/form/manifest-partner/${manifestData?.id}`);

            }).catch((err) => {
                this.setState({ reloadCountLoader: false })
            });
        }
    }

    handleFetchManifestSuccessRes = (response) => {

        let { loaders } = this.state;
        const { form } = this.props;

        const manifestData = {
            id: response?.manifest?.id,
            manifestCode: response?.manifest?.manifestCode,
            destinationLoc: {
                id: response?.manifest?.destLocId,
                name: response?.manifest?.destLocName
            }
        }

        const destinations = [];
        destinations.push({
            id: response?.manifest?.destLocId,
            name: response?.manifest?.destLocName
        })
        loaders = {
            ...this.state.loaders,
            destinationDisabled: true,
        }

        this.setState({
            manifestData: manifestData, destinations, generatedManifest: true,
            showWaybillScan: true, disableButton: true, manifest: manifestData?.manifestCode,
            manifestId: manifestData?.id, loaders, editManifestID: manifestData?.manifestCode
        }, () => {
            form.setFieldsValue({
                manifestID: manifestData?.manifestCode,
                destination: response?.manifest?.destLocId,
                destinationType: response?.manifest?.destLocEntityType
            }, () => {
                this.setState({ pageDataLoading: false });
                this.getConsignments(response?.manifest?.destLocId);
            })
        })

        var module = "inventory";
        if (this.state.flowType !== FlowTypes.FORWARD) {
            module = 'rto';
        }
        this.props.history.replace(`/appv2${this.isMobile ? '/mweb' : ''}/${module}/form/manifest-partner/${response?.manifest?.manifestCode}`);

    }

    fetchPaginationLinkedWaybill = (paginationData) => {

        this.setState({ selectedAwbCurrentPageNo: paginationData.pageNo })

        const reqBody = {
            manifestCode: this.state.editManifestID || this.state.manifestId,
            pageNo: paginationData.pageNo,
            pageSize: 5
        }

        if (this.state.searchText) {
            reqBody['waybillNo'] = this.state.searchText
        }

        fetchLinkedWaybill(reqBody)
            .then(response => {
                this.setState({ linkedWaybillPaginatedRes: response, selectedAwb: response.consignments })
            })
            .catch(err => {
                console.error("Fetch linked waybill failed with following error", err)
            })

    }

    generateManifest = () => {
        const { setFieldsValue } = this.props.form;

        if ((this.props.appConfig?.enableRearch?.enableManifestWriteOps === 'true' && this.state.flowType != "RTO")
            || (this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true' && this.state.flowType === "RTO")) {
            generateManifestCode().then(response => {
                let { generatedManifest } = this.state;
                generatedManifest = false;
                const data = response;
                setFieldsValue({ "manifestID": data?.manifestCode }, () => {
                    this.setState({ generatedManifest }, _ => this.manifestCodeChange(undefined, true));
                })
            })
        }
        else {
            let queryParam = "MANIFEST";
            if (this.props?.location?.pathname.includes("inventory")) {
                queryParam = "MANIFEST";
            } else if (this.props?.location?.pathname.includes("rto")) {
                queryParam = "RTO_MANIFEST";
            }
            apiUtils.apiRequest({
                url: `/b2b/v1/fetch/code?code=${queryParam}`,
                method: "GET"
            }).then(response => {
                let { generatedManifest } = this.state;
                generatedManifest = false;
                const data = response?.data?.response;
                setFieldsValue({ "manifestID": data?.code }, () => {
                    this.setState({ generatedManifest }, _ => this.manifestCodeChange(undefined, true));
                })
            })
        }

    }

    changePartner = () => {
        this.props.form.setFieldsValue({
            "destinationType": undefined,
            "destination": undefined
        })
    }

    changeDestinationType = (value) => {
        const partner = this.props.form.getFieldValue("partner");
        this.props.form.setFieldsValue({
            "destination": undefined
        })
        const payload = {
            filters: {
                entityTypes: [value],
                entityIds: [partner],
                roles: [],
                isConsiderCreatorId: false,
                status: true,
                fetchObjects: ["ADDRESS"]
            }
        };
        if (value === "CUSTOMER") {
            delete payload["filters"]["entityIds"];
            delete payload["filters"]["isConsiderCreatorId"];
        }

        apiUtils.apiRequest({
            url: `/b2b/v1/partners/${partner}/locations/fetch`,
            method: "POST",
            data: payload
        }).then(response => {
            const destinations = removeDuplicateLocations(response?.data?.response?.locations);
            this.setState({ destinations })
        })
    }

    fetchSourceLocation = (value) => {
        if (value) {
            if (
                this.state.flowType === FlowTypes.FORWARD &&
                this.props.appConfig?.enableRearch?.enableManifestWriteOps == "true"
            ) {

                fetchDestinationLoc(value)
                    .then(response => {
                        this.setState({ destinations: response.locations })
                    })
                    .catch(err => {
                        console.error("Fetch destination location failed with following error ", err)
                    })

            } else if (
                this.state.flowType === FlowTypes.RTO &&
                this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === "true"
            ) {

                fetchRtoDestinationLoc(value)
                    .then(response => {
                        this.setState({ destinations: response.locations })
                    })
                    .catch(err => {
                        console.error("Fetch destination location failed with following error ", err)
                    })

            } else {
                const payload = {
                    filters: {
                        entityTypes: ["PARTNER"],
                        entityIds: [Constants.PARTNER_ID],
                        canConsiderSourcePartners: true,
                        roles: [],
                        isConsiderCreatorId: false,
                        status: true,
                        fetchObjects: [],
                        pageSize: undefined,
                        pageNo: undefined,
                        partialContentSearch: value
                    }
                };
                apiUtils.apiRequest({
                    url: `/b2b/v1/partners/${Constants.PARTNER_ID}/locations/fetch`,
                    method: "POST",
                    data: payload
                }).then(response => {
                    const destinations = removeDuplicateLocations(response?.data?.response?.locations);
                    this.setState({ destinations })
                })
            }
        }
    }

    getConsignments = value => {
        let { consignmentLoading } = this.state.loaders;
        consignmentLoading = false;
        this.setState({
            loaders: {
                ...this.state.loaders,
                consignmentLoading
            },
            disableButton: false,
            pendingAwbCountLoading: true
        })

        if (this.state.manifestId) {
            this.setState({ disableButton: true })
        }

        if (this.state.flowType === FlowTypes.FORWARD &&
            this.props.appConfig?.enableRearch?.enableManifestReadOps == 'true'
        ) {

            const params = {
                destinationLocId: value
            }

            fetchPendingWaybillCount(params)
                .then(response => {
                    this.setState({
                        pendingAwbCount: response.shipmentCount,
                        pendingAwbCountLoading: false
                    })
                })
                .catch(err => {
                    console.error("fetchPendingWaybillCount failed with following error", err)
                })

        } else if ((this.state.flowType === FlowTypes.RTO &&
            this.props.appConfig?.enableRearch?.enableRtoManifestPendingWaybillCnt === "true")) {

            const params = {
                destinationLocId: value
            }
            this.setState({ pendingAwbCountLoading: true })

            fetchRtoPendingWaybillCount(params)
                .then(response => {
                    this.setState({
                        pendingAwbCount: response.shipmentCount,
                        pendingAwbCountLoading: false
                    })
                })
                .catch(err => {
                    console.error("fetchPendingWaybillCount failed with following error", err)
                    this.setState({ pendingAwbCountLoading: false })
                })

        } else {
            const payload = {
                filters: {
                    isArrived: true,
                    isBookedOnly: true,
                    flowTypes: [this.state.flowType],
                    pageNo: 1,
                    pageSize: 1,
                }
            };
            if (this.state.flowType === "RTO") {
                payload.filters["statuses"] = ["RTO_RETURN_TO_ORIGIN", "RTO_IN"];
                payload.filters["destinationLocationIds"] = [
                    value
                ];
            } else {
                payload.filters["destinationLocationIds"] = [
                    value
                ];
            }
            payload["filters"]["deviceFilter"] = {
                deviceType: "DESKTOP"
            };
            try {
                apiUtils.apiRequest({
                    url: "/b2b/v1/consignments/unassigned/count",
                    method: "POST",
                    data: payload
                }).then(response => {
                    this.setState({
                        pendingAwbCount: response.data.response.totalCount,
                        pendingAwbCountLoading: false
                    })
                }).catch(err => {
                    notifyApiError(err, "ERROR")
                })
            } catch (err) {
                notifyApiError(err, "ERROR")
            }
        }
    }

    removeWaybill = row => this.removeSelected(row)

    removeSelected = waybillDetails => {
        const { selectedAwb } = this.state;
        var awbIndex = selectedAwb.indexOf(waybillDetails)
        const waybill = {
            id: selectedAwb[awbIndex] ? selectedAwb[awbIndex].id : null,
            waybillNo: selectedAwb[awbIndex] ? selectedAwb[awbIndex].waybillNo : null,
            flowType: this.state.flowType
        };
        this.manageWaybill(waybill, "DELINK", awbIndex);
    }

    createManifest = () => {
        let { loaders } = this.state;
        let { createLoading } = loaders;
        createLoading = true;
        this.setState({
            loaders: {
                ...loaders,
                createLoading
            }
        })

        this.props.form.validateFieldsAndScroll((errors, values) => {
            if (errors) return;

            if (this.state.flowType === FlowTypes.RTO &&
                this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === "true") {

                const payload = {
                    "destLocationId": values["destination"],
                    "manifestCode": values["manifestID"]?.trim()
                }

                createRtoManifest(payload)
                    .then(response => {
                        this.handleCreateManifestSuccessRes(response, payload, loaders)
                    })
                    .catch(err => {
                        console.error("createRtoManifest failed with following error", err)
                        this.setState({
                            loaders: {
                                selectedLoading: false,
                                consignmentLoading: false,

                                createLoading: false
                            }
                        })
                    })

            } else if (this.state.flowType === FlowTypes.FORWARD &&
                this.props.appConfig?.enableRearch?.enableManifestWriteOps === "true") {
                const payload = {
                    "destLocationId": values["destination"],
                    "manifestCode": values["manifestID"]?.trim()
                }

                createManifests(payload).then(response => {
                    this.handleCreateManifestSuccessRes(response, payload, loaders)
                })
                    .catch((err) => {
                        console.error("createManifests failed with following error", err)
                        this.setState({
                            loaders: {
                                selectedLoading: false,
                                consignmentLoading: false,

                                createLoading: false
                            }
                        })
                    })

            } else {
                const payload = {
                    "request": [
                        {
                            "manifest": {
                                ...(this.state.manifestId && { id: this.state.manifestId }),
                                "manifestCode": values["manifestID"]?.trim(),
                                "partner": {
                                    "id": Constants.PARTNER_ID
                                },
                                "destinationLoc": {
                                    "id": values["destination"]
                                },
                                "originatedUser": {
                                    "id": Constants.sessionUser.id
                                },
                                "originatedLoc": {
                                    "id": Constants.userLocationId
                                },
                                "consignments": [],
                                "flowType": this.state.flowType,
                                "manifestStatus": "PENDING",
                                "createdAt": moment().valueOf()
                            },
                            "manifestCode": values["manifestID"]?.trim(),
                            "referenceId": values["manifestID"]?.trim(),
                            "action": this.state.manifestId ? "UPDATE_DETAILS" : "CREATE"
                        }
                    ]
                }

                apiUtils.apiRequest({
                    url: "/b2b/v1/manifest/update",
                    method: "POST",
                    data: payload
                }).then(response => {
                    const data = response?.data?.response;

                    if (data?.responses[0]?.sync) {
                        notifyApiSuccess(data?.responses[0]?.reason, "SUCCESS!")

                        createLoading = false;
                        let destinationDisabled = false;
                        if (this.state.pendingAwbCount <= 0) {
                            destinationDisabled = true;
                        }
                        this.setState({
                            "manifest": data?.responses[0]?.manifestCode?.trim(),
                            manifestId: data?.responses[0]?.manifestBO?.id,
                            manifestData: data?.responses[0]?.manifestBO,
                            showWaybillScan: true,
                            disableButton: true,
                            isManifestLockButtonEnabled: true,
                            generatedManifest: true,
                            isEdit: true,
                            loaders: {
                                ...loaders,
                                createLoading,
                                destinationDisabled
                            }
                        })
                    } else {
                        notifyApiError(data?.responses[0]?.reason, "ERROR!!");
                        this.setState({
                            loaders: {
                                selectedLoading: false,
                                consignmentLoading: false,

                                createLoading: false
                            }
                        })
                    }
                })
            }
        });
    }

    handleCreateManifestSuccessRes = (response, payload, loaders) => {

        notifyApiSuccess("SUCCESS! Manifest created " + response?.manifestCode)
        let createLoading = false;
        let destinationDisabled = false;
        if (this.state.pendingAwbCount <= 0) {
            destinationDisabled = true;
        }
        const manifestData = {
            "manifestId": response?.manifestId,
            "manifestCode": response?.manifestCode?.trim(),
            "destinationLoc": payload.destLocationId,
            "originatedLoc": this.sessionUser?.luserLocationId

        }
        this.setState({
            manifest: response?.manifestCode?.trim(),
            manifestId: response?.manifestId,
            editManifestID: this.props.appConfig?.enableRearch?.enableManifestReadOps === 'true' ? response?.manifestCode?.trim() : response?.manifestId,
            manifestData: manifestData,
            showWaybillScan: true,
            disableButton: true,
            isManifestLockButtonEnabled: true,
            generatedManifest: true,
            isEdit: true,
            loaders: {
                ...this.state.loaders,
                createLoading,
                destinationDisabled
            }
        })

    }

    fetchAndPrintManifest = () => {
        const entityData = {
            "manifestCode": this.state.manifest,
            "originLocation": this.state.manifestData.originatedLoc.name ? this.state.manifestData.originatedLoc.name : Constants.sessionUser?.location?.name,
            "destinationLocation": this.state.manifestData.destinationLoc.name ? this.state.manifestData.destinationLoc.name : this.selectedDestinationName,
            "totalShipmentCount": this.state.manifestData.totalWaybillCount,
            "flowType": this.state.flowType,
            "manifestLockTime": moment(),
        }
        var printModal = Modal.info({
            title: "",
            width: 0,
            style: {
                display: "none"
            },
            content: <PrintButton
                buttonText="Print"
                style={{ display: "none" }}
                isDisabled={false}
                isPrintTriggered={true}
                entityData={entityData}
                isAutoPrintEnabled={false}
                onAfterPrint={_ => {
                    printModal.destroy();
                }}
            />
        })
    }

    openLockManifest = () => {
        this.setState({ showConfirmLockModal: true })
        if (this.state.IsPartnerLocationIdEqualsManifestLocationId) {
            this.fetchAndPrintManifest();
        }
    }

    handleLockConfirm = () => {
        const { loaders } = this.state;
        this.setState({
            loaders: {
                ...loaders,
                manifestLockLoading: true
            }
        })
        if ((this.props.appConfig?.enableRearch?.enableManifestWriteOps === 'true' && this.state.flowType != "RTO")
            || (this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true' && this.state.flowType === "RTO")) {
            let payload = {
                manifestCode: this.state.manifest,
                shouldLock: true
            }
            lockUnlockManifest(payload).then(resp => {
                this.setState({
                    loaders: {
                        ...loaders,
                        manifestLockLoading: false
                    }
                })
                notifyApiSuccess(resp, "Success");
                let destModule = "inventory";
                if (this.props?.location?.pathname.includes("inventory")) {
                    destModule = "inventory";
                } else if (this.props?.location?.pathname.includes("rto")) {
                    destModule = "rto";
                }
                if (this.isMobile) {
                    return this.props.history.replace(`/appv2/mweb/${destModule}/dashboard/manifest-partner`)
                }
                const redirectionPath = `/appv2/${destModule}/dashboard/manifest-partner`;
                this.props.history.push(redirectionPath);
                this.setState({
                    showConfirmLockModal: false
                })
            })
                .catch(err => {
                    notifyApiError(err?.errors, "ERROR");
                    this.setState({
                        loaders: {
                            ...loaders,
                            manifestLockLoading: false
                        },
                        showConfirmLockModal: false
                    })
                })
        }
        else {
            apiUtils.apiRequest({
                url: "/b2b/v1/manifest/lock",
                method: "POST",
                data: {
                    manifestCode: this.state.manifest,
                    shouldLock: true
                }
            }).then(responseData => {
                this.setState({
                    loaders: {
                        ...loaders,
                        manifestLockLoading: false
                    }
                })
                if (responseData && responseData.data) {
                    if ([200, 201, 202].includes(responseData.data.status.code)) {
                        notifyApiSuccess(responseData.data.status.message, "Success");
                        let destModule = "inventory";
                        if (this.props?.location?.pathname.includes("inventory")) {
                            destModule = "inventory";
                        } else if (this.props?.location?.pathname.includes("rto")) {
                            destModule = "rto";
                        }
                        if (this.isMobile) {
                            return this.props.history.replace(`/appv2/mweb/${destModule}/dashboard/manifest-partner`)
                        }
                        const redirectionPath = `/appv2/${destModule}/dashboard/manifest-partner`;
                        this.props.history.push(redirectionPath);
                        this.setState({
                            showConfirmLockModal: false
                        })
                    } else {
                        notifyApiError(responseData.data.status.message, "ERROR");
                        this.setState({
                            showConfirmLockModal: false
                        })
                    }
                }
            }).catch(err => {
                this.setState({
                    loaders: {
                        ...loaders,
                        manifestLockLoading: false
                    },
                    showConfirmLockModal: false
                })
            })
        }

    };

    handleCancelLock = () => {
        this.setState({
            ...this.state,
            showConfirmLockModal: false
        });
    };

    changeWaybill = e => this.setState({ waybill: e.target.value });

    enterWaybill = e => {
        const value = e.target.value;
        if (!value || value.trim() === "") {
            return notifyApiError("Enter waybill number to scan!")
        }
        const scanInput = document.getElementById("scanWaybillInput");
        if (scanInput) {
            scanInput.blur();
            scanInput.setAttribute("readonly", "readonly");
        }
        this.addSelected(value);
    }

    enableMwebInputField = _ => {
        const scanInput = document.getElementById("scanWaybillInput");
        if (scanInput) {
            scanInput.focus();
            scanInput.removeAttribute("readonly");
        }
    }

    manifestWaybillScan = async value => {
        let consignment = this.state.selectedAwb.find((item, i) => {
            return item.waybillNo === value
        });
        if (consignment) {
            if (!this.isMobile) {
                notifyApiError(`${value} waybill is already selected.`, "Error")
            }
            soundNotificationService.playGeneralWarning();
            this.setState({
                waybill: undefined, selectedConsignmentBO: {},
                scanResult: {
                    message: `${value} waybill is already selected`,
                    sync: false
                }
            })
            this.enableMwebInputField();
        } else {
            let consignmentBO = await this.callScanApi(value);

            if (!this.isMobile) {
                notifyApiSuccess(`Waybill linked Successfully!`, "Success");
            }

            let { selectedAwb } = this.state;
            selectedAwb.push(consignmentBO);
            selectedAwb = [...this.state.selectedAwb];
            var pendingCount = { ...this.state }.pendingAwbCount;
            soundNotificationService.playSuccess();
            --pendingCount;
            this.setState({
                selectedAwb,
                waybill: undefined,
                selectedConsignmentBO: {},
                scanResult: {
                    action: "LINK",
                    manifestBO: {
                        consignments: [consignmentBO]
                    },
                    sync: true
                },
                pendingAwbCount: pendingCount
            }, this.enableMwebInputField);
        }

    }
    // Used only for rto link to manifest
    callScanApi = awb => {
        const { loaders } = this.state;
        this.setState({
            loaders: {
                ...loaders,
                selectedLoading: true
            }
        })
        return new Promise(resolve => {
            const { getFieldValue } = this.props.form;
            const destination = getFieldValue("destination");
            if (this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true'
                && this.state.flowType === "RTO") {
                const payload = {
                    waybillNo: awb,
                    manifestCode: this.state.manifest
                }
                linkRTOWaybill(payload).then(response => {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        }
                    })
                    if (response.deepLinkUrl) {
                        this.setState({
                            deepLinkUrl: response.deepLinkUrl,
                            loaders: {
                                ...loaders,
                                selectedLoading: false
                            },
                            waybill: undefined,
                        })
                        this.enableMwebInputField();
                    } else {
                        const updatedLinkedWaybillRes = {
                            ...this.state.linkedWaybillPaginatedRes,
                            totalCount: this.state.linkedWaybillPaginatedRes.totalCount + 1
                        }
    
                        this.setState(
                            {
                                linkedWaybillPaginatedRes: updatedLinkedWaybillRes
                            })
    
                        // const waybillLinkedInfo = {
                        //     waybillNo: response?.waybillNo,
                        //     customerName: response?.consignment?.customerName,
                        //     destinationLocationName: response?.consignment?.cityCode + "-" + response?.consignment?.zipcode
                        // }
    
    
                        const waybillLinkedInfo = {
                            waybillNo: response?.waybillNo,
                            customer: {
                                name: response?.consignment?.customerName
                            },
                            pincode: {
                                city: {
                                    cityCode: response?.consignment?.cityCode
                                },
                                zipcode: response?.consignment?.zipcode
                            }
                        }
    
                        resolve(waybillLinkedInfo)
                    }
                    

                }).catch(err => {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        }
                    })
                    soundNotificationService.playGeneralWarning();
                    this.enableMwebInputField();
                    this.setState({ waybill: undefined })
                })
            }
            else {
                const payload = {
                    waybillNumber: awb,
                    partnerId: Constants.PARTNER_ID,
                    locationId: Constants.userLocationId,
                    manifestId: this.state.manifestId,
                    manifestCode: this.state.manifest,
                    destinationId: destination,
                    flowType: this.state.flowType
                };
                apiUtils.apiRequest({
                    url: "/b2b/v1/manifest/waybill_scan",
                    method: "POST",
                    data: payload
                }).then(response => {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        }
                    })
                    if (response?.data?.status && response?.data?.status.code == 200) {
                        resolve(response.data.response.consignmentBO)
                    } else {
                        soundNotificationService.playGeneralWarning();
                        this.enableMwebInputField();
                        this.setState({ waybill: undefined })
                    }
                });
            }
        })
    }

    addSelected = async value => {
        let barcode = value.trim();
        barcode = trimByConfig(
            barcode,
            this.props.appConfig["waybillLengthRestriction"]
        );

        if (this.props.appConfig?.enableRearch?.enableManifestWriteOps == 'true'
            && this.state.flowType != "RTO") {

            this.manageWaybill(value.trim(), "LINK", 1)

        } else {

            // ga.trackEvent(`${this.state.flowType === "RTO" ? "rto_" : ""}mani_link_via_input`, "track");
            let barcode = value.trim();
            barcode = trimByConfig(
                barcode,
                this.props.appConfig["waybillLengthRestriction"]
            );

            const bypassInscanLocationIds = this.props.appConfig ? this.props.appConfig.bypassInscanLocationIds : [];
            const bypassInscanEnabled = bypassInscanLocationIds && bypassInscanLocationIds.indexOf(Constants.userLocationId + "") > -1;

            const rtoWaybillScanApiEnabledLocations = this.props.appConfig ? this.props.appConfig.rtoWaybillScanApiEnabledLocations : [];
            const rtoWaybillScanApiEnabled = rtoWaybillScanApiEnabledLocations && rtoWaybillScanApiEnabledLocations.indexOf(Constants.userLocationId + "") > -1;
            if (this.props.appConfig.enableRearch.enableRtoManifestWriteOps == 'true') {
                if (this.state.flowType == FlowTypes.RTO) {
                    this.manifestWaybillScan(barcode);
                    return;
                }
            }
            else {
                if ((!bypassInscanEnabled && this.state.flowType == "FORWARD") || (this.state.flowType == FlowTypes.RTO && rtoWaybillScanApiEnabled)) {
                    this.manifestWaybillScan(barcode);
                    return;
                }
            }

            let { awbIndex, consignmentId, waybillNo, message } = await this.getScanIndex(barcode);

            if (awbIndex >= 0) {
                let waybill = {
                    id: consignmentId ? consignmentId : null,
                    waybillNo: waybillNo ? waybillNo : null,
                    flowType: this.state.flowType
                };
                this.manageWaybill(waybill, "LINK", awbIndex);

            } else if (message) {
                notifyApiError(message, "Error")
                soundNotificationService.playGeneralWarning();
                this.enableMwebInputField();
            } else if (awbIndex === undefined) {
                if (!this.isMobile) {
                    notifyApiError(`${value} waybill is already selected.`, "Error")
                }
                soundNotificationService.playGeneralWarning();
                this.setState({
                    waybill: undefined, selectedConsignmentBO: {},
                    scanResult: {
                        message: `${value} waybill is already selected`,
                        sync: false
                    }
                })
                this.enableMwebInputField();
            } else {
                if (!this.isMobile) {
                    notifyApiError(`${value} waybill not found`, "Error")
                }
                soundNotificationService.playGeneralWarning();
                this.setState({
                    waybill: undefined, selectedConsignmentBO: {},
                    scanResult: {
                        message: `${value} waybill not found`,
                        sync: false
                    }
                })
                this.enableMwebInputField();
            }
        }
    }

    manageWaybill(waybill, action, index,) {

        if (this.props.appConfig?.enableRearch?.enableManifestWriteOps === 'true'
            && this.state.flowType === "FORWARD") {

            if (action === "LINK") {

                const { loaders } = this.state;
                this.setState({
                    loaders: {
                        ...loaders,
                        selectedLoading: true
                    }
                })

                let payload = {
                    "manifestCode": this.state.manifest,
                    "waybillNo": waybill
                }

                let { selectedAwb, selectedAwbCurrentPageNo, linkedWaybillPaginatedRes } = this.state;

                linkWaybill(payload).then(response => {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: true
                        }
                    })

                    if (response.deepLinkUrl) {
                        this.setState({
                            deepLinkUrl: response.deepLinkUrl,
                            loaders: {
                                ...loaders,
                                selectedLoading: false
                            },
                            waybill: undefined,
                        })
                    } else {
                        var pendingCount = this.state.pendingAwbCount;
                        const maxPage = linkedWaybillPaginatedRes?.maxPage;
    
                        if ((selectedAwbCurrentPageNo === maxPage) || maxPage === 0 || maxPage === undefined || maxPage === null) {
                            // const waybillLinkedInfo = {
                            //     waybillNo: response?.waybillNo,
                            //     customerName: response?.consignment?.customerName,
                            //     destinationLocationName: response?.consignment?.cityCode + "-" + response?.consignment?.zipcode
                            // }
    
                            const waybillLinkedInfo = {
                                waybillNo: response?.waybillNo,
                                customer: {
                                    name: response?.consignment?.customerName
                                },
                                pincode: {
                                    city: {
                                        cityCode: response?.consignment?.cityCode
                                    },
                                    zipcode: response?.consignment?.zipcode
                                }
                            }
    
                            selectedAwb.push(waybillLinkedInfo);
                            selectedAwb = [...this.state.selectedAwb];
    
                            const updatedLinkedWaybillRes = {
                                ...this.state.linkedWaybillPaginatedRes,
                                totalCount: this.state.linkedWaybillPaginatedRes.totalCount + 1
                            }
    
                            this.setState(
                                {
                                    linkedWaybillPaginatedRes: updatedLinkedWaybillRes
                                }
                            )
                        }
                        --pendingCount;
                        if (!this.isMobile) {
                            notifyApiSuccess(`Waybill linked Successfully!`, "Success");
                        }
    
                        soundNotificationService.playSuccess();
    
                        const finalResponse = { ...response, sync: true }
    
                        this.setState({
                            selectedAwb,
                            waybill: undefined,
                            selectedConsignmentBO: {},
                            loaders: {
                                ...loaders,
                                selectedLoading: false
                            },
                            scanResult: {
                                ...finalResponse, // set data
                                action: action
                            },
                            pendingAwbCount: pendingCount
    
                        });
                    }
                    this.enableMwebInputField();

                }).catch((err) => {

                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        selectedConsignmentBO: {},
                        waybill: undefined,
                        scanResult: err
                    })
                    // notifyApiError("ERROR!! Cannot link waybill " + err.);
                    soundNotificationService.playGeneralWarning();
                    this.enableMwebInputField();
                })

            } else if (action === "DELINK") {
                const { loaders } = this.state;
                this.setState({
                    loaders: {
                        ...loaders,
                        selectedLoading: true
                    }
                })

                let payload = {
                    "manifestCode": this.state.manifest,
                    "waybillNo": waybill?.waybillNo
                }

                let { selectedAwb, selectedAwbCurrentPageNo } = this.state;
                deLinkWaybill(payload).then(response => {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: true
                        }
                    })

                    notifyApiSuccess(`Waybill delinked Successfully!`, "Success");

                    soundNotificationService.playSuccess();

                    const linkStateUpdate = {
                        waybill: undefined,
                        selectedConsignmentBO: {},
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        scanResult: {
                            action: action
                        },
                    }

                    const maxPage = this.state.linkedWaybillPaginatedRes?.maxPage;

                    if ((selectedAwb.length == 1 && maxPage > 1) ||
                        (maxPage > 1 && selectedAwbCurrentPageNo < maxPage)) {

                        const paginationReq = {
                            pageNo: 1
                        }

                        if (selectedAwbCurrentPageNo != 1) {
                            paginationReq['pageNo'] = selectedAwbCurrentPageNo - 1
                        }

                        this.fetchPaginationLinkedWaybill(paginationReq)

                    } else {

                        selectedAwb.splice(index, 1);
                        selectedAwb = [...this.state.selectedAwb];

                        const updatedLinkedWaybillRes = {
                            ...this.state.linkedWaybillPaginatedRes,
                            totalCount: this.state.linkedWaybillPaginatedRes.totalCount - 1
                        }

                        linkStateUpdate['selectedAwb'] = selectedAwb
                        linkStateUpdate['linkedWaybillPaginatedRes'] = updatedLinkedWaybillRes

                    }

                    var pendingCount = { ...this.state }.pendingAwbCount;
                    ++pendingCount;

                    linkStateUpdate['pendingAwbCount'] = pendingCount

                    this.setState(linkStateUpdate);

                }).catch((err) => {

                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        selectedConsignmentBO: {},
                        waybill: undefined,
                        scanResult: err
                    })
                    soundNotificationService.playGeneralWarning();
                })

            }

        }
        else if (this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true'
            && this.state.flowType === "RTO") {
            if (action == "DELINK") {

                let { selectedAwb, selectedAwbCurrentPageNo } = this.state;
                const { loaders } = this.state;
                this.setState({
                    loaders: {
                        ...loaders,
                        selectedLoading: true
                    }
                })

                let payload = {
                    "manifestCode": this.state.manifest,
                    "waybillNo": waybill?.waybillNo
                }

                deLinkRTOWaybill(payload).then(response => {

                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: true
                        }
                    })

                    soundNotificationService.playSuccess();

                    const linkStateUpdate = {
                        waybill: undefined,
                        selectedConsignmentBO: {},
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        scanResult: {
                            action: action
                        },
                    }

                    const maxPage = this.state.linkedWaybillPaginatedRes?.maxPage;

                    if ((selectedAwb.length == 1 && maxPage > 1) ||
                        (maxPage > 1 && selectedAwbCurrentPageNo < maxPage)) {

                        const paginationReq = {
                            pageNo: 1
                        }

                        if (selectedAwbCurrentPageNo != 1) {
                            paginationReq['pageNo'] = selectedAwbCurrentPageNo - 1
                        }

                        this.fetchPaginationLinkedWaybill(paginationReq)

                    } else {

                        selectedAwb.splice(index, 1);
                        selectedAwb = [...this.state.selectedAwb];

                        const updatedLinkedWaybillRes = {
                            ...this.state.linkedWaybillPaginatedRes,
                            totalCount: this.state.linkedWaybillPaginatedRes.totalCount - 1
                        }

                        linkStateUpdate['selectedAwb'] = selectedAwb
                        linkStateUpdate['linkedWaybillPaginatedRes'] = updatedLinkedWaybillRes

                    }

                    var pendingCount = { ...this.state }.pendingAwbCount;
                    ++pendingCount;

                    linkStateUpdate['pendingAwbCount'] = pendingCount

                    this.setState(linkStateUpdate);


                })
                    .catch(err => {
                        this.setState({
                            loaders: {
                                ...loaders,
                                selectedLoading: false
                            },
                            selectedConsignmentBO: {},
                            waybill: undefined,
                            scanResult: err
                        })
                        soundNotificationService.playGeneralWarning();
                    })

            }
        }
        else {
            const { loaders } = this.state;
            this.setState({
                loaders: {
                    ...loaders,
                    selectedLoading: true
                }
            })
            const { getFieldValue } = this.props.form;
            const destination = getFieldValue("destination");
            let manifestData = {
                request: [
                    {
                        manifest: {
                            manifestCode: this.state.manifest,
                            partner: {
                                id: Constants.PARTNER_ID
                            },
                            destinationLoc: {
                                id: destination
                            },
                            originatedUser: {
                                id: Constants.sessionUser.id
                            },
                            originatedLoc: {
                                id: Constants.userLocationId
                            },
                            consignments: [waybill],
                            flowType: this.state.flowType,
                            updatedAt: Date.now(),
                            id: this.state.manifestId,
                        },
                        manifestCode: this.state.manifest,
                        referenceId: this.state.manifest,
                        action: action
                    }
                ]
            };
            let { selectedAwb } = this.state;

            apiUtils.apiRequest({
                url: "/b2b/v1/manifest/update",
                method: "POST",
                data: manifestData
            }).then(responseData => {
                if (responseData?.data?.response?.responses[0]?.sync || responseData?.data?.response?.length > 1) {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: true
                        }
                    })

                    var pendingCount = { ...this.state }.pendingAwbCount;
                    if (action === "DELINK") {

                        selectedAwb.splice(index, 1);
                        selectedAwb = [...this.state.selectedAwb];
                        ++pendingCount;
                        notifyApiSuccess(`Waybill delinked Successfully!`, "Success");
                    }
                    else if (action === "LINK") {

                        selectedAwb.push(this.state.selectedConsignmentBO);
                        selectedAwb = [...this.state.selectedAwb];
                        --pendingCount;
                        if (!this.isMobile) {
                            notifyApiSuccess(`Waybill linked Successfully!`, "Success");
                        }
                    }
                    soundNotificationService.playSuccess();

                    this.setState({
                        selectedAwb,
                        waybill: undefined,
                        selectedConsignmentBO: {},
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        scanResult: {
                            ...responseData?.data?.response?.responses[0],
                            action: action
                        },
                        pendingAwbCount: pendingCount
                    });
                } else {
                    this.setState({
                        loaders: {
                            ...loaders,
                            selectedLoading: false
                        },
                        selectedConsignmentBO: {},
                        waybill: undefined,
                        scanResult: responseData?.data?.response?.responses[0]
                    })
                    notifyApiError(responseData?.data?.response?.responses[0]?.reason, "Error")
                    soundNotificationService.playGeneralWarning();
                }
                if (action === "LINK") {
                    this.enableMwebInputField();
                }
            });
        }

    }

    getScanIndex = awb => {

        return new Promise(resolve => {
            const { getFieldValue } = this.props.form;
            let index = -1;
            let consignment = {};
            const destination = getFieldValue("destination");
            const payload = {
                waybillNumber: awb,
                partnerId: Constants.PARTNER_ID,
                locationId: Constants.userLocationId,
                manifestId: this.state.manifestId,
                manifestCode: this.state.manifest,
                destinationId: destination,
                flowType: this.state.flowType,
                addToManifest: false
            };
            apiUtils.apiRequest({
                url: "/b2b/v1/manifest/waybill_scan",
                method: "POST",
                data: payload,
                ignoreStatus: true
            }).then(response => {
                if (response?.data?.status && response?.data?.status.code == 200) {
                    consignment = response.data.response.consignmentBO;
                    this.setState({
                        selectedConsignmentBO: consignment
                    }, () => {
                        resolve({ awbIndex: 1, consignmentId: consignment?.id, waybillNo: consignment?.waybillNo });
                    })

                } else {
                    index = -1;
                    if (index === -1) {
                        this.state.selectedAwb.find((item, i) => {
                            if (item.waybillNo === awb) {
                                index = undefined;
                                return undefined;
                            }
                        });
                    }

                    if (_.isEmpty(consignment)) {
                        this.setState({
                            selectedConsignmentBO: {},
                            waybill: undefined,
                        }, () => {
                            resolve({ awbIndex: index, consignmentId: undefined, waybillNo: undefined, message: response?.data?.status?.message });
                        })

                    }
                }
            });
        })
    }


    goBack = () => (window.location.pathname?.indexOf("mweb") > -1) ? this.props.history.push("/appv2/mweb/inventory/dashboard/manifest-partner") : ((this.state.flowType === FlowTypes.RTO) ? this.props.history.push("/appv2/rto/dashboard/manifest-partner") : this.props.history.push("/appv2/inventory/dashboard/manifest-partner"));


    openBulkManifestBooking = () => {
        this.setState({ visibleBulk: true, clearManifestFile: false });
    }

    cancelBulk = () => {
        this.setState({ visibleBulk: false, uploadedFile: "", clearManifestFile: true, bulkSuccess: [], bulkFail: [], bulkData: undefined });
    }

    onUploadManifestFinish = e => {
        this.setState({ uploadedFile: e }, () => {
            this.sync();
        });
    }

    resync = () => {
        this.setState({ bulkData: undefined }, () => {
            this.sync();
        })
    }

    sync = () => {
        this.setState({ bulkLoading: true }, () => {
            let payload = {
                "partnerId": Constants.PARTNER_ID,
                "manifestCode": this.state.manifest,
                "destinationLocId": this.props.form.getFieldValue("destination"),
                "originatedUserId": Constants.sessionUser.id,
                "originatedLocId": Constants.userLocationId,
                "id": this.state.manifestId,
                "url": this.state?.uploadedFile,
                "referenceId": moment().valueOf()
            }

            apiUtils.apiRequest({
                url: "/b2b/v1/uploads/manifest",
                method: "POST",
                data: payload
            }).then(response => {
                if (response?.data?.response?.sync) {

                    let succssCount = 0, failCount = 0, success = [], fail = [];
                    let data = response?.data?.response;
                    succssCount = data?.successfulSync;
                    failCount = data?.unsuccessfulSync;
                    if (succssCount > 0) {
                        success = data?.successfulResponse;
                    }
                    if (failCount > 0) {
                        fail = data?.failureResponse;
                    }

                    this.setState({ bulkSuccess: success, bulkFail: fail, bulkData: data, bulkLoading: false });
                    this.loadManifestData();
                } else {
                    this.setState({ bulkLoading: false })
                }
            });
        })

    }

    openSelectedModal = _ => this.setState({ selectedAWBModal: true })

    viewPendingList = _ => {
        if (this.state.manifestData && this.state.manifestData?.destinationLoc?.id) {
            var reportParams = {
                resource: {
                    question: 2228
                },
                params: {
                    location_id: Constants.userLocationId,
                    partner_id: Constants.CURRENT_USER_ID,
                    destination_location_id: this.state.manifestData?.destinationLoc?.id
                }
            }
            if (this.state.flowType === FlowTypes.RTO) {
                reportParams = {
                    ...reportParams,
                    resource: {
                        question: 2227
                    }
                }
            }
            window.open(`/appv2/reports/waybills-pending-for-manifest?reportParams=${btoa(JSON.stringify(reportParams))}`);
        }
    }

    manifestCodeChange = (e, disableInput) => {
        if (disableInput === true || e?.key?.toLowerCase() == "enter") {
            if (document.querySelector("input#ManifestForm_manifestID").value?.trim() !== "") {
                this.setState({
                    manifestCodeFilled: true
                }, _ => {
                    setTimeout(() => {
                        if (document.querySelector("input#ManifestForm_manifestID")) {
                            document.querySelector("input#ManifestForm_manifestID").blur();
                        }
                        if (document.querySelector("div#ManifestForm_destination")) {
                            document.querySelector("div#ManifestForm_destination").click();
                        }
                    }, 400);
                });
            }
        }
    }

    editManifestCode = _ => {
        this.setState({
            manifestCodeFilled: false
        }, _ => {
            if (document.querySelector("input#ManifestForm_manifestID")) {
                document.querySelector("input#ManifestForm_manifestID").focus();
            }
        })
    }

    closeActionModal = () => {
        this.setState({ deepLinkUrl: undefined});
    }

    render() {

        const formItemLayout = {
            formlayout: "vertical",
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        };

        const { getFieldDecorator } = this.props.form;
        const { pendingAwb, loaders, disableButton, showWaybillScan, manifest, selectedAwb, bulkLoading, manifestPermission,
            selectedColumns, rearchSelectedColumns, generatedManifest, visibleBulk, bulkData, bookingSuccessMsg, bookingFailMsg, selectedAWBModal,
            errorResponseMsg, successfulBookingText, unsuccessfulBookingText, unsuccessfulAsyncText, isManifestLockButtonEnabled,
            linkedWaybillPaginatedRes, flowType, deepLinkUrl
        } = this.state;

        const pagination = {
            total: linkedWaybillPaginatedRes?.totalCount,
            pageSize: 5,
            current: linkedWaybillPaginatedRes?.currentPageNo,
            onChange: pageNo => this.fetchPaginationLinkedWaybill({ pageNo: pageNo }),
            showSizeChanger: false
        }

        let rearchManifest = false

        if ((flowType === FlowTypes.FORWARD &&
            this.props.appConfig?.enableRearch?.enableManifestReadOps === 'true') ||
            (flowType === FlowTypes.RTO &&
                this.props.appConfig?.enableRearch?.enableRtoManifestReadOps === 'true')) {
            rearchManifest = true
        }

        let disableBulk = false

        if ((flowType === FlowTypes.FORWARD &&
            this.props.appConfig?.enableRearch?.enableManifestWriteOps === 'true') ||
            (flowType === FlowTypes.RTO &&
                this.props.appConfig?.enableRearch?.enableRtoManifestWriteOps === 'true')) {
            disableBulk = true
        }

        return (
            <Spin className="flex-box justify-contente-center" spinning={this.state.pageDataLoading}>
                <Row gutter={[12, 12]} style={this.isMobile ? { height: "calc(100vh - 125px)", margin: 0 } : undefined}>
                    <Col span={this.isMobile ? undefined : 12}
                        style={((!this.isMobile) || (this.isMobile && !generatedManifest)) ? undefined : { display: "none" }}>
                        <Card bodyStyle={{ height: pendingAwb?.length > 0 ? 1000 : 656 }}>
                            <h3>Create Manifest</h3>
                            <Form {...formItemLayout}>
                                <Row>
                                    <Col span={24} className="user-invite-form">
                                        <Form.Item colon={false} label={<span className="form-label-text">Manifest ID</span>}>
                                            {
                                                getFieldDecorator("manifestID", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Please input manifest ID!",
                                                        },
                                                        {
                                                            pattern: "^[a-zA-Z0-9_\-]*$",
                                                            message: "Manifest ID should contain only alphabets and numbers!"
                                                        }
                                                    ],
                                                })(
                                                    <Input disabled={generatedManifest || this.state.manifestCodeFilled}
                                                        placeholder="Enter Manifest ID" onKeyDown={this.manifestCodeChange}
                                                        suffix={
                                                            !generatedManifest ?
                                                                this.state.manifestCodeFilled ?
                                                                    <Icon type="edit" onClick={this.editManifestCode} /> :
                                                                    <Icon type="sync" onClick={this.generateManifest} /> :
                                                                <></>
                                                        } />
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className="user-invite-form">
                                        <Form.Item colon={false} label={<span className="form-label-text">Destination</span>}>
                                            {
                                                getFieldDecorator("destination", {
                                                    rules: [{
                                                        required: true,
                                                        message: "Please select destination!",
                                                    }],
                                                })(
                                                    <Select placeholder="Select Destination" showSearch={true} showArrow={true}
                                                        filterOption={(input, option) => option.props.children.toString()?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                        disabled={selectedAwb.length > 0 || this.state.loaders.destinationDisabled || this.state.isManifestLockButtonEnabled}
                                                        notFoundContent={"Type 3 characters to search..."}
                                                        onSearch={this.fetchSourceLocation} onChange={this.getConsignments}
                                                        onSelect={(value, optionObject) => this.selectedDestinationName = optionObject.props.label} >
                                                        {this.state?.destinations?.map(destination => (
                                                            <Select.Option key={destination.id} value={destination.id} label={destination.name} >
                                                                {destination.name}
                                                            </Select.Option>
                                                        ))}
                                                    </Select>
                                                )
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <div className="flex-box flex-gap-m justify-content-flex-end" >
                                                <Button type={"default"} onClick={this.goBack}>Cancel</Button>

                                                {
                                                    showWaybillScan && !isManifestLockButtonEnabled ?
                                                        <Button type={"primary"} loading={loaders?.createLoading}
                                                            disabled={!(selectedAwb.length)} onClick={this.openLockManifest} >
                                                            {this.state.IsPartnerLocationIdEqualsManifestLocationId ? "Lock and Print Manifest" : "Lock Manifest"}
                                                        </Button> :
                                                        <Button type={"primary"} loading={loaders?.createLoading}
                                                            disabled={this.state.isManifestLockButtonEnabled ? !(selectedAwb.length) : disableButton}
                                                            onClick={this.state.isManifestLockButtonEnabled ? this.openLockManifest : this.createManifest} >
                                                            {this.state.isManifestLockButtonEnabled ? (this.state.IsPartnerLocationIdEqualsManifestLocationId ? "Lock and Print Manifest" : "Lock Manifest") : "Create Manifest"}
                                                        </Button>
                                                }

                                                {!disableBulk &&
                                                    manifestPermission.bulk && (this.state.isEdit || this.state.isLocked) &&
                                                    <Button
                                                        type="primary"
                                                        onClick={this.openBulkManifestBooking}
                                                        disabled={this.state.pendingAwbCount <= 0}
                                                    >Bulk manifest Booking</Button>
                                                }
                                            </div>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    {
                        !this.isMobile &&
                        <Col span={12}>
                            <Row gutter={[12, 24]}>
                                <Col span={24}>
                                    <Card>
                                        <h3> Selected AWB( Count - {
                                            (rearchManifest ? this.state.linkedWaybillPaginatedRes?.totalCount : selectedAwb?.length) > 0 ? rearchManifest ?
                                                this.state.linkedWaybillPaginatedRes?.totalCount : selectedAwb?.length : 0} ) </h3>
                                        <Table loading={loaders?.selectedLoading}
                                            columns={selectedColumns}
                                            dataSource={selectedAwb ? selectedAwb : []}
                                            pagination={rearchManifest ?
                                                pagination : { pageSize: 5 }} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card>
                                        <div className="flex-box justify-content-space-between align-items-center">
                                            <h3>
                                                Pending AWB ( Count - {
                                                    (this.state.pendingAwbCountLoading ? <Spin spinning /> :
                                                        (this.state.pendingAwbCount && this.state.pendingAwbCount > 0 ? this.state.pendingAwbCount : 0))
                                                })
                                            </h3>
                                            <Button className="lsn-btn-primary" icon="reload" disabled={!(this.state.editManifestID || this.state.manifestId)}
                                                onClick={_ => {
                                                    if (this.state.editManifestID || this.state.manifestId) {
                                                        this.loadManifestData();
                                                    }
                                                }} loading={this.state.reloadCountLoader} > Reload Count </Button>
                                            {
                                                (this.state.editManifestID || this.state.manifestId) &&
                                                <Button onClick={this.viewPendingList} icon="eye"
                                                    className="lsn-btn-secondary">
                                                    View Pending List
                                                </Button>
                                            }
                                        </div>
                                        {
                                            showWaybillScan &&
                                            <>
                                                <Form.Item label={<span className="form-label-text">Scan the waybills for {manifest}</span>}>
                                                    <Input placeholder={"Scan Waybill no."} suffix={<Icon type="search" />}
                                                        onChange={this.changeWaybill} value={this.state.waybill}
                                                        onPressEnter={this.enterWaybill} />
                                                </Form.Item>
                                                <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "right" }}>
                                                    <Button type="default" onClick={this.goBack}>Done</Button>
                                                </Form.Item>
                                            </>
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    }
                    {
                        this.isMobile && generatedManifest &&
                        <div className="flex-column justify-content-space-between" style={{ height: "100%" }}>
                            <ManifestMwebScan manifestData={this.state.manifestData} changeWaybill={this.changeWaybill} openSelectedModal={this.openSelectedModal}
                                waybill={this.state.waybill} enterWaybill={this.enterWaybill} selectedAwb={selectedAwb} scanResult={this.state.scanResult}
                                pendingAwbCountLoading={this.state.pendingAwbCountLoading} pendingAwbCount={this.state.pendingAwbCount}
                                selectedLoading={this.state.loaders.selectedLoading}
                                destinationLocation={this.state.destinations && this.state.destinations.length > 0 ? this.state.destinations[0].name : null}
                                isRearchEnabled={this.props?.appConfig?.enableRearch?.enableManifestWriteOps === "true"} />
                            <div className="flex-box spacer-m">
                                {
                                    showWaybillScan && !isManifestLockButtonEnabled ?
                                        <Button type={"primary"} loading={loaders?.createLoading} className="lsn-btn-primary flex-1"
                                            disabled={!(selectedAwb.length)} onClick={this.openLockManifest} >
                                            {this.state.IsPartnerLocationIdEqualsManifestLocationId ? "Lock and Print Manifest" : "Lock Manifest"}
                                        </Button> :
                                        <Button type={"primary"} loading={loaders?.createLoading} className="lsn-btn-primary flex-1"
                                            disabled={this.state.isManifestLockButtonEnabled ? !(selectedAwb.length) : disableButton}
                                            onClick={this.state.isManifestLockButtonEnabled ? this.openLockManifest : this.createManifest} >
                                            {this.state.isManifestLockButtonEnabled ? (this.state.IsPartnerLocationIdEqualsManifestLocationId ? "Lock and Print Manifest" : "Lock Manifest") : "Create Manifest"}
                                        </Button>
                                }
                            </div>
                        </div>
                    }
                    <ConfirmLockModal showConfirmLockModal={this.state.showConfirmLockModal}
                        handleLockConfirm={this.handleLockConfirm} handleCancelLock={this.handleCancelLock}
                        loading={this.state.loaders.manifestLockLoading}
                        message={(
                            <div style={{ textAlign: "center", fontSize: "inherit" }}>
                                <div>Are you sure you want to Lock the manifest?</div>
                                <div>Once the manifest is locked, no more shipments can be added to it!</div>
                            </div>
                        )} />
                    <Modal visible={visibleBulk} title="Bulk Manifest Booking" onCancel={this.cancelBulk}
                        footer={
                            bulkData &&
                            <>
                                <Button type="primary" onClick={this.resync}>Re-Sync</Button>
                                <Button type="primary" onClick={this.cancelBulk}>Done</Button>
                            </>
                        }>
                        <div>
                            <small> For accurate results </small>
                            <div>
                                Download sample upload format <a target="_blank" href="https://s3-ap-southeast-1.amazonaws.com/loadshare-v2-staging/documents/templates/xlsx/bulk-manifest-template.xlsx">here</a>
                            </div>
                            <FileUpload
                                fileUploadType={UPLOAD_BUTTON_TYPE.DRAGDROP}
                                directUpload={true}
                                disableUploadButton={false}
                                clearFileAfterUpload={this.state.clearManifestFile}
                                onUploadFinish={($events) => this.onUploadManifestFinish($events)}
                                path="manifest"
                                acceptTypes={this.state.acceptTypes}
                                objKey="manifest"
                            />
                            {
                                bulkData &&
                                <Spin spinning={bulkLoading}>
                                    {
                                        (bulkData?.sync && bulkData?.successfulSync > 0) ?
                                            <Alert message={bookingSuccessMsg} type="success" /> :
                                            <Alert message={bookingFailMsg} type="error" />
                                    }
                                    <br />
                                    {
                                        (bulkData?.successfulSync) ?
                                            <p>
                                                {successfulBookingText}
                                                {bulkData.successfulSync}
                                            </p> :
                                            <></>
                                    }
                                    {
                                        (bulkData?.unsuccessfulSync) ?
                                            <p>
                                                {unsuccessfulBookingText}
                                                {bulkData.unsuccessfulSync}
                                            </p> :
                                            <></>
                                    }
                                    {
                                        (bulkData?.unsuccessfulAssignSync) ?
                                            <p>
                                                {unsuccessfulAsyncText}
                                                {bulkData.unsuccessfulAssignSync}
                                            </p> :
                                            <></>
                                    }
                                    {
                                        (bulkData?.failureResponseUrl) ?
                                            <div>
                                                <a href={bulkData.failureResponseUrl} target="_blank">
                                                    Click here to view failed upload waybills
                                                </a>
                                            </div> :
                                            <></>
                                    }
                                    {

                                        (bulkData.failureResponse.length)
                                            ? <Collapse bordered={false}>
                                                <Collapse.Panel header={errorResponseMsg} key="1">
                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={bulkData.failureResponse}
                                                        renderItem={failResponse => !failResponse.sync ? <List.Item>{failResponse.waybillNo} - {failResponse.reason}</List.Item> : <></>}
                                                    />
                                                </Collapse.Panel>
                                            </Collapse>
                                            : <></>
                                    }
                                    {
                                        (bulkData.duplicateResponses.length)
                                            ? <Collapse bordered={false}>
                                                <Collapse.Panel header={errorResponseMsg} key="1">
                                                    <List
                                                        size="small"
                                                        bordered
                                                        dataSource={bulkData.duplicateResponses}
                                                        renderItem={duplicateResponse => !duplicateResponse.sync ? <List.Item>{duplicateResponse.waybillNo} - {duplicateResponse.reason}</List.Item> : <></>}
                                                    />
                                                </Collapse.Panel>
                                            </Collapse>
                                            : <></>
                                    }
                                </Spin>
                            }
                        </div>
                    </Modal>

                    <Modal centered={true} width={"95%"} footer={false} visible={selectedAWBModal} closable={true}
                        onCancel={_ => this.setState({ selectedAWBModal: false })}
                        title={<h3> Selected AWB( Count - {selectedAwb?.length} ) </h3>}>

                        <Table loading={loaders?.selectedLoading} columns={rearchManifest ? rearchSelectedColumns : selectedColumns}
                            dataSource={selectedAwb ? selectedAwb : []} pagination={{ pageSize: 5 }} />
                    </Modal>
                </Row >
                <ScanActionRequiredPopupModal handleClose={this.closeActionModal}
                        open={deepLinkUrl ? true : false}
                        qrText={deepLinkUrl || ''} 
                    />
            </Spin>
        )

    }
}

const ConfirmLockModal = (props) => {
    const { showConfirmLockModal, message, handleLockConfirm, handleCancelLock, loading } = props;
    return (
        <Modal
            title="Need confirmation"
            visible={showConfirmLockModal}
            closable={true}
            onCancel={handleCancelLock}
            footer={[
                <div style={{ display: "flex", justifyContent: "space-between", }}>
                    <Button
                        block
                        style={{ backgroundColor: "#4caf50", color: "white" }}
                        onClick={handleLockConfirm}
                        loading={loading}
                    >YES</Button>
                    <Button
                        block
                        type="danger"
                        onClick={handleCancelLock}
                    >NO</Button>
                </div>
            ]}
        >
            {message}
        </Modal>
    )
}

const mapStateToProps = state => ({
    appConfig: state?.app?.configurations,
});

const mapDispatchToProps = _ => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: "ManifestForm", onValuesChange: (props, val) => { } })(ManifestForm));